export const data = {
  klassrum: [
    {
      name: "NP i par",
      grid: [
        [
          {
            id: null,
            person: 0,
          },
          {
            id: "item-1730560410661",
            person: 0,
          },
          {
            id: "item-1730560411333",
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: "item-1730560429581",
            person: 0,
          },
          {
            id: "item-1730560429926",
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: "item-1730560438917",
            person: 0,
          },
          {
            id: "item-1730560439374",
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: "item-1730560458717",
            person: 0,
          },
          {
            id: "item-1730560459420",
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: "item-1730560460270",
            person: 0,
          },
          {
            id: "item-1730560460654",
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
        ],
        [
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
        ],
        [
          {
            id: null,
            person: 0,
          },
          {
            id: "item-1730560415870",
            person: 0,
          },
          {
            id: "item-1730560416302",
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: "item-1730560430678",
            person: 0,
          },
          {
            id: "item-1730560431070",
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: "item-1730560440004",
            person: 0,
          },
          {
            id: "item-1730560440526",
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: "item-1730560457590",
            person: 0,
          },
          {
            id: "item-1730560458037",
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: "item-1730560461077",
            person: 0,
          },
          {
            id: "item-1730560461572",
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
        ],
        [
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
        ],
        [
          {
            id: null,
            person: 0,
          },
          {
            id: "item-1730560421758",
            person: 0,
          },
          {
            id: "item-1730560422191",
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: "item-1730560431830",
            person: 0,
          },
          {
            id: "item-1730560432141",
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: "item-1730560441349",
            person: 0,
          },
          {
            id: "item-1730560442013",
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: "item-1730560456526",
            person: 0,
          },
          {
            id: "item-1730560456885",
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: "item-1730560462678",
            person: 0,
          },
          {
            id: "item-1730560495021",
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
        ],
        [
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
        ],
        [
          {
            id: null,
            person: 0,
          },
          {
            id: "item-1730560422998",
            person: 0,
          },
          {
            id: "item-1730560423381",
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: "item-1730560432597",
            person: 0,
          },
          {
            id: "item-1730560433206",
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: "item-1730560443470",
            person: 0,
          },
          {
            id: "item-1730560443853",
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: "item-1730560455205",
            person: 0,
          },
          {
            id: "item-1730560455869",
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: "item-1730560465213",
            person: 0,
          },
          {
            id: "item-1730560465982",
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
        ],
        [
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
        ],
        [
          {
            id: null,
            person: 0,
          },
          {
            id: "item-1730560424149",
            person: 0,
          },
          {
            id: "item-1730560424485",
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: "item-1730560433829",
            person: 0,
          },
          {
            id: "item-1730560434295",
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: "item-1730560444533",
            person: 0,
          },
          {
            id: "item-1730560444869",
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: "item-1730560453885",
            person: 0,
          },
          {
            id: "item-1730560454454",
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: "item-1730560466541",
            person: 0,
          },
          {
            id: "item-1730560467069",
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
        ],
        [
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
        ],
        [
          {
            id: null,
            person: 0,
          },
          {
            id: "item-1730560425142",
            person: 0,
          },
          {
            id: "item-1730560425645",
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: "item-1730560435149",
            person: 0,
          },
          {
            id: "item-1730560435510",
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: "item-1730560445733",
            person: 0,
          },
          {
            id: "item-1730560446261",
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: "item-1730560453013",
            person: 0,
          },
          {
            id: "item-1730560453357",
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: "item-1730560467949",
            person: 0,
          },
          {
            id: "item-1730560468493",
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
        ],
        [
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
        ],
        [
          {
            id: null,
            person: 0,
          },
          {
            id: "item-1730560426229",
            person: 0,
          },
          {
            id: "item-1730560427102",
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: "item-1730560436078",
            person: 0,
          },
          {
            id: "item-1730560436909",
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: "item-1730560447805",
            person: 0,
          },
          {
            id: "item-1730560448501",
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: "item-1730560451797",
            person: 0,
          },
          {
            id: "item-1730560452421",
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: "item-1730560469580",
            person: 0,
          },
          {
            id: "item-1730560470069",
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
        ],
        [
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
        ],
        [
          {
            id: null,
            person: 0,
          },
          {
            id: "item-1730560427909",
            person: 0,
          },
          {
            id: "item-1730560428293",
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: "item-1730560437429",
            person: 0,
          },
          {
            id: "item-1730560437853",
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: "item-1730560449605",
            person: 0,
          },
          {
            id: "item-1730560450077",
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: "item-1730560450653",
            person: 0,
          },
          {
            id: "item-1730560451165",
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: "item-1730560470965",
            person: 0,
          },
          {
            id: "item-1730560471765",
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
        ],
        [
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
        ],
        [
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
          {
            id: null,
            person: 0,
          },
        ],
      ],
      cols: 10,
      rows: 10,
    },
    {
      name: "H221",
      grid: [
        [
          { id: null, person: 0 },
          { id: null, person: 0 },
          { id: null, person: 0 },
          { id: null, person: 0 },
          { id: null, person: 0 },
          { id: "item-1728395558391", person: 0},
          { id: "item-1728395558639", person: 0},
        ],
        [
          { id: null, person: 0 },
          { id: null, person: 0 },
          { id: null, person: 0 },
          { id: "item-1728395560144", person: 0},
          { id: null, person: 0 },
          { id: null, person: 0 },
          { id: null, person: 0 },
        ],
        [
          { id: null, person: 0 },
          { id: null, person: 0 },
          { id: "item-1728395557488", person: 0},
          { id: null, person: 0 },
          { id: null, person: 0 },
          { id: null, person: 0 },
          { id: null, person: 0 },
        ],
        [
          { id: null, person: 0 },
          { id: null, person: 0 },
          { id: null, person: 0 },
          { id: null, person: 0 },
          { id: null, person: 0 },
          { id: null, person: 0 },
          { id: null, person: 0 },
        ],
        [
          { id: "item-1728395563304", person: 0},
          { id: "item-1728395562912", person: 0},
          { id: "item-1728395557831", person: 0},
          { id: null, person: 0 },
          { id: null, person: 0 },
          { id: "item-1728395562504", person: 0},
          { id: "item-1728395559832", person: 0},
        ],
        [
          { id: null, person: 0 },
          { id: "item-1728395561288", person: 0},
          { id: "item-1728395560888", person: 0},
          { id: null, person: 0 },
          { id: null, person: 0 },
          { id: null, person: 0 },
          { id: null, person: 0 },
        ],
      ],
      cols: 7,
      rows: 6,
    },
  ],
  klasser: [
    {
      namn: "peters klass",
      personer: [
        "",
        "Alexander",
        "Alexander",
        "Rasmus",
        "Sammy",
        "Roham",
        "Oscar",
        "Alicia",
        "Tore",
        "Isac",
        "Hugo",
        "Maja",
        "Alice",
        "Prodromos",
        "Gabriel",
        "Ali k",
        "Katerina",
        "Oscar B",
        "Isabella",
        "Tassen",
        "Tilde",
        "Elisabeth",
        "Jamie",
        "Nike",
        "Elsa",
        "Anna",
        "Iman",
        "Anna",
        "Emil",
        "Alexia",
        "Alva",
        "Astrid",
        "Markus",
        "Sid",
        "Hampus",
        "Hannes",
        "William",
        "Bosse",
        "Lylly",
        "Joel",
        "Otto",
        "Erika",
        "Vincent",
        "Ali G",
        "Mona",
        "Walter",
        "Ellin",
        "Ellen",
        "Maria",
        "Klara",
        "Thea",
        "Philip",
        "Turid",
        "Sueda",
        "Sador",
        "Hedvig",
        "Ines",
        "Hampus",
        "Linnèa",
        "Danielle",
        "Vera",
        "William",
        "Sofia",
        "Emilia",
        "Emilia",
        "Erik",
        "snakeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
        "Astrid",
        "Filicia",
        "Hussein",
        "Eli",
        "Signe",
        "Annie",
        "Jakob",
        "Moa",
        "Andrea",
        "Inès",
        "Emil",
        "Farhiya",
        "Wilma",
        "Stella",
        "Isak",
        "Arvid",
        "Viggo",
        "Linus",
        "Oliver",
        "Samuel",
        "Jakob",
        "Eliot",
        "Eric",
        "Artur",
        "Noah",
        "Isak",
        "Malcolm",
        "Tony",
        "Aria",
        "Noah",
        "Livia",
        "Sixsten",
        "Alex",
        "Matilda",
        "Ryan",
        "Uktam",
        "Olle",
        "Altan",
        "Juni",
        "Maja",
        "Rebecca",
        "Stina",
        "Kevin",
        "Melike",
        "Elin",
        "Elsa",
        "Gabriel",
        "Lo",
        "Enisa",
        "Myra",
        "Alsu",
        "Ida",
        "Madeleine",
        "Kiara",
        "Astrid",
        "Oscar W",
        "Stella",
        "Tindra",
        "Julia",
        "Alvina",
        "Sofia",
        "Ceasar",
      ],
    },
    {
      namn: "6C",
      personer: [
        "",
        "Ada",
        "Isabella",
        "Agnes",
        "Veda",
        "An Na",
        "Liam",
        "Maxim",
        "Dante",
        "Axel",
        "Benthe",
        "Michkat",
        "Ida",
        "Anna-Maria",
        "Randy",
        "Malin",
        "Hadi",
        "Vihaan",
        "Kian",
        "Jonathan",
        "Kevin",
        "Saian",
        "Lydia",
        "Hugo",
        "",
        "Karl",
        
      ],
    },
    {
      namn: "7G",
      personer: [
        "",
        "henry",
        "Kalle",
        "Fredrik",
        "carl",
        "Johan",
        "Artur",
        "Mattias",
        "Prodromosappapap",
      ],
    },
  ],
};
